import { useEffect } from "react"
import { navigate } from "gatsby"
import useBrowserLanguage from "../assets/hooks/useBrowserLanguage"

const IndexPage = ({ location }) => {
  const redirectLang = useBrowserLanguage()

  useEffect(() => {
    navigate(`/${redirectLang}`)
  })

  return null
}

export default IndexPage
